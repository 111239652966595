import React from 'react';
import Layout from './index';

import messages from '../data/messages/de';
import "@formatjs/intl-pluralrules/locale-data/de"


export default (props) => (
  <Layout
    {...props}
    messages={messages}
  />
);